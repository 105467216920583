html {
    scroll-behavior: smooth
}

.pigHeader {
    height: 40px;
    width: 40px;
    
}

body {
    /*background-image: url('./preview.png');*/

}

.lazy-background {
    /*background-image: url("hero-placeholder.jpg"); /* Placeholder image */
    background-color: rgba(0, 0, 0, 0.75);
  }
  
  .lazy-background.visible {
    background-image: url("./preview.png"); /* The final image */
  }


div#root {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    overflow:auto;
}

div#walletBar {
    float: right;
    margin-top: -85px;
    margin-right: 15px;

}

.MuiButton-containedPrimary {
    /*color: #fff !important;
    background-color: #464646 !important; */
    color: rgba(0, 0, 0, 0.87) !important;
    background-color: #e0e0e0 !important;
    
}

div#main {
    /*background-color: rgba(216, 105, 210, 0.85) ;*/
    background-color: rgb(105, 175, 216, 0.9);
    font-family: "Patrick Hand SC", cursive;
    font-size: 1.5em;  /*0.875em;  14px/16=0.875em */
    color: #000;
    display: "block";
    margin: auto;
    margin-top: 100px;
    max-width: 800px;
    padding: 20px;
    text-align: center;
    border-radius: 40px;
    border: #fff 4px solid;
    height: 100%;
    flex: 1 0 auto;
}

div#howToMint {
    margin-top: 40px;
}

div#howToMint li {
    text-align: left;
}

div#mintInfo {
    border: #FFF 2px solid;
    background-color: rgba(216, 105, 210, 1);
    box-shadow: 5px 5px 5px #555;
    margin: 0px 40px;
    border-radius: 40px;
    padding: 20px;
    font-weight: bold;
}

div#footer {
    flex-shrink: 0;

}

div#inlineAlert {}

div.social a {
    margin: 15px;
}
a {
    color:rgb(0, 0, 0)
}
span.mintstartdate {
    font-size: 1.75em;
}
span.MuiButton-label {
    font-weight: bold;
}

.MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}

img.artistsImg {
    border:rgb(255, 255, 255) 2px solid;
    border-radius: 40px;
    box-shadow: 5px 5px 5px #555;
    max-height: 300px;
    max-width: 100%;
}
span.imgcaption {
    font-style: italic;
    font-size: 0.8em;
}
img.artistImg {
    max-height: 300px;
    max-width: 100%;
    border:rgb(255, 255, 255) 2px solid;
    border-radius: 40px;
    box-shadow: 5px 5px 5px #555;
}

.disclaimer {
    font-size: 0.7em;
    margin-top: 25px;
}

